<template>
  <v-tooltip bottom max-width="20em">
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="deep-purple" dark v-bind="attrs" v-on="on">
        {{ icon }}
      </v-icon>
    </template>
    <span>
      {{ content }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "mdi-information-outline",
    },
  },
};
</script>
