<template>
  <div>
    <Loader :visible="isLoading" />
    <v-row class="foundation" v-if="!isLoading">
      <v-col offset-md="3" md="6" sm="12">
        <div class="text-h5 text-md-h4 text-center mb-8 form-title tway-gray-dark--text">Presentación de Proyecto</div>
        <div
          class="text-h5 text-md-h4 text-left mb-2 form-title tway-gray-dark--text"
          style="font-size: 16.5pt !important"
        >
          Mi Perfil
          <Tooltip :content="perfil" />
        </div>

        <!-- Presentación del Proyecto -->

        <v-card class="py-6 mb-10" elevation="4">
          <div class="px-6">
            <div class="input-label">Nombre Empresa</div>
            <div class="text-profile roboto">
              {{ getCompany.name ? getCompany.name : "" }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Negocio principal</div>
            <div class="text-profile roboto">
              {{ getCompany.industrialSector ? getCompany.industrialSector.name : "" }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Acerca de la Empresa</div>
            <div class="text-profile roboto">
              {{ getCompany.contributionValue }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Inicio de Actividades</div>
            <div class="text-profile roboto">
              {{ getCompany.trajectory }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Sitio Web</div>
            <div class="text-profile roboto">
              {{ getCompany.web }}
            </div>
          </div>
          <v-divider class="mb-4 mt-3" />
          <div class="px-6">
            <div class="input-label">Perfil LinkedIn</div>
            <div class="text-profile roboto">
              {{ getCompany.linkedIn }}
            </div>
          </div>
        </v-card>

        <!-- Fundamentación del Proyecto -->

        <div
          class="text-h5 text-md-h4 text-left mb-2 form-title tway-gray-dark--text"
          style="font-size: 18pt !important"
        >
          Desafío Estratégico
          <Tooltip :content="desafio" />
        </div>
        <v-card class="py-6 mb-6" elevation="4">
          <div class="px-6">
            <div v-if="filled">
              <div class="d-flex justify-space-around">
                <div class="text-center">
                  <div class="input-label mb-4" style="font-weight: 600">
                    IMTD
                    <Tooltip :content="imtd" />
                  </div>
                  <v-progress-circular
                    :indeterminate="!getScore"
                    :rotate="360"
                    :size="100"
                    :width="14"
                    :value="Math.round(getScore)"
                    color="#16C62E"
                  >
                    {{ Math.round(getScore) }}%
                  </v-progress-circular>
                </div>
                <div class="d-block mr-4">
                  <div class="d-flex mb-2 justify-space-around">
                    <div>
                      <div class="text-subtitle-1 mb-2">Dimensión a impactar</div>
                      <div class="tway-headline-3 mr-4">
                        {{ dimension.name ? dimension.name : "" }}
                      </div>
                    </div>
                    <v-progress-circular
                      class="ml-6"
                      :indeterminate="!dimension"
                      :rotate="360"
                      :size="60"
                      :width="8"
                      :value="Math.round(dimension.score)"
                      color="#16C62E"
                    >
                      {{ Math.round(dimension.score) }}%
                    </v-progress-circular>
                  </div>
                  <v-divider class="mb-4 mt-4" />
                  <div class="d-flex mb-2 justify-space-around">
                    <div>
                      <div class="text-subtitle-1 mb-2">Componente a impactar</div>
                      <div class="tway-headline-3 mr-4">
                        {{ subdimensionSelection.name ? subdimensionSelection.name : "" }}
                      </div>
                    </div>
                    <v-progress-circular
                      :indeterminate="!subdimensionSelection"
                      class="ml-6"
                      :rotate="360"
                      :size="60"
                      :width="8"
                      :value="Math.round(subdimensionSelection.score)"
                      color="#16C62E"
                    >
                      {{ Math.round(subdimensionSelection.score) }}%
                    </v-progress-circular>
                  </div>
                </div>
              </div>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation v-else>
              <v-row>
                <v-col cols="12"> <div class="mb-4">Dimensión a impactar</div></v-col>
                <v-col cols="12">
                  <v-select
                    return-object
                    :rules="[requiredObject('Dimensión a impactar')]"
                    :items="getDimensions"
                    @change="subdimension = null"
                    v-model="dimension"
                    outlined
                    item-value="code"
                    item-text="name"
                    class="mb-4 roboto"
                    color="deep-purple accent-4"
                    placeholder="Selecciona una Dimensión de la lista"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <div :class="['input-label', 'mb-4', { 'grey--text': dimension === null }, 'lighten-5']">
                    Componente de Dimensión a impactar
                  </div>
                </v-col>
                <v-col cols="12"
                  ><v-select
                    return-object
                    :rules="[requiredObject('Componente de Dimensión a impactar')]"
                    :items="subdimension ? subdimension : []"
                    v-model="subdimensionSelection"
                    outlined
                    item-value="code"
                    item-text="name"
                    class="mb-4 roboto"
                    color="deep-purple accent-4"
                    placeholder="Selecciona un Componente de la Lista"
                /></v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" align="center">
                  <v-btn
                    class="text-uppercase text-body-1 roboto"
                    rounded
                    text
                    x-small
                    color="deep-purple accent-4"
                    style="font-size: 11pt !important"
                    :disabled="!flag"
                    @click="filled = true"
                  >
                    Mostrar resultados
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <div
          class="text-h5 text-md-h4 text-left mb-2 form-title tway-gray-dark--text"
          style="font-size: 18pt !important"
        >
          Servicio según recomendaciones
          <Tooltip :content="servicio" />
        </div>
        <v-card class="py-6 mb-6" elevation="4">
          <div class="px-6">
            <choice
              :data="getCategoriesBySubdimensionName"
              @array="recomendationName = $event"
              ref="choice"
              class="roboto"
            />
          </div>
        </v-card>
        <div class="d-flex justify-center">
          <v-btn
            large
            depressed
            rounded
            :disabled="!valid"
            @click="createProject()"
            class="tway-violet white--text roboto elevation-3"
          >
            comenzar formulario DE DESCRIPCIÓN DE PROYECTO
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Tooltip from "@/components/framework/Tooltip";
import ValidatorsService from "@/services/validatorsService";
import CallForTenderService from "@/services/callForTenderService";
import { mapGetters, mapActions } from "vuex";
import Choice from "@/components/elements/Choice.vue";

export default {
  name: "FoundationCorporative",
  components: {
    Tooltip,
    Loader,
    choice: Choice,
  },
  data() {
    return {
      isLoading: true,
      perfil: "Se muestran los datos de tu perfil para revisar si están correctamente actualizados",
      desafio: "El desafío estratégico es tu prioridad para mejorar tu IMTD.",
      servicio: "Servicios que se presentan en la sección Recomendaciones de tu Dashboard para mejorar tu IMTD",
      imtd: "Índice de Madurez en Transformación Digital",
      dimension: null,
      subdimension: null,
      subdimensionSelection: {},
      valid: false,
      filled: false,
      flag: false,
      recomendationName: [],
      formData: {
        companyInfo: {},
        services: {},
        initialScore: 0,
        dimension: {},
        subDimension: {},
      },
    };
  },
  async created() {
    this.recomendationName = [];
    Promise.all([
      this.setCompanyProfile(),
      this.setUmapiUserData(),
      this.setSurvey("a123"),
      this.setRecommendations({ clientId: this.getClientId }),
    ]).then(() => {
      let dtiParams = {
        surveyId: this.getSurvey.id,
        userId: this.getUmapiUser.id,
      };
      if (this.$route.query.dimension && this.$route.query.subdimension) {
        this.dimension = this.getDimensions.find((e) => e.code == this.$route.query.dimension);
        this.subdimensionSelection = this.dimension.subDimensions.find((e) => e.code == this.$route.query.subdimension);
        this.filled = true;
      }
      try {
        this.setDti(dtiParams);
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    });
  },

  methods: {
    ...mapActions("DigitalTransformationStore", ["setCategoriesBySubdimensionName", "setDti", "setSurvey"]),
    ...mapActions({ setProject: "createProject/setProjectFromDB" }),
    ...mapActions("UserStore", ["setCompanyProfile", "setUmapiUserData"]),
    ...mapActions("ImtdStore", ["setRecommendations"]),
    required: (propertyType) => ValidatorsService.required(propertyType),
    requiredObject: (propertyType) => ValidatorsService.requiredObject(propertyType),
    createProject() {
      this.isLoading = true;
      this.formData.companyInfo = this.getCompany;
      this.formData.services = this.recomendationName;
      this.formData.initialScore = this.getDti.score;
      this.formData.dimension = this.getDti.dimensions.find((e) => e.name == this.dimension.name);
      this.formData.subDimension = this.formData.dimension.subdimensions.find(
        (e) => e.name == this.subdimensionSelection.name
      );
      CallForTenderService.createProject(this.formData)
        .then((project) => {
          this.setProject(project).then(() => this.$router.push(`create-project/${project.id}`));
        })
        .catch((err) => this.$log.error(err))
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    ...mapGetters("UserStore", ["getCompany", "getUmapiUser", "getClientId"]),
    ...mapGetters("ImtdStore", ["getScore", "getDimensions"]),
    ...mapGetters("DigitalTransformationStore", ["getCategoriesBySubdimensionName", "getDti", "getSurvey"]),
  },
  watch: {
    dimension() {
      if (!this.$route.query.dimension) {
        this.subdimension = this.dimension.subDimensions;
      }
    },
    async subdimensionSelection() {
      if (this.dimension && this.subdimensionSelection) {
        this.flag = true;
        await this.setCategoriesBySubdimensionName(this.subdimensionSelection.name);
      }
    },
    recomendationName() {
      if (this.dimension && this.subdimensionSelection) {
        this.valid = true;
      }
    },
  },
};
</script>

<style></style>
